import { dAppName } from 'config';
// import PrivateSale from 'pages/PrivateSale';
import withPageTitle from './components/PageTitle';
import ComingSoon from './pages/ComingSoon';
import Home from './pages/Home';
import Unlock from './pages/Unlock';
// import Whitelist from './pages/Whitelist';
import Vesting from 'pages/Vesting';
import PublicSale from 'pages/PublicSale';
import Staking from 'pages/Staking';

export const routeNames = {
  home: '/',
  whitelist: '/whitelist',
  comingsoon: '/comingsoon',
  // privatesale: '/privatesale',
  // publicsale: '/',
  vesting: '/vesting',
  unlock: '/unlock'
};

const routes: Array<any> = [
  {
    path: routeNames.home,
    title: 'Home',
    component: Home
  },
  // {
  //   path: routeNames.whitelist,
  //   title: 'Whitelist',
  //   component: Whitelist
  // },
  {
    path: routeNames.comingsoon,
    title: 'ComingSoon',
    component: ComingSoon
  },
  // {
  //   path: routeNames.publicsale,
  //   title:'Public Sale',
  //   component: PublicSale
  // },
  {
    path: routeNames.vesting,
    title:'Vesting',
    component: Vesting
  },
  {
    path: routeNames.unlock,
    title: 'Unlock',
    component: Unlock
  }
];

const mappedRoutes = routes.map((route) => {
  const title = route.title ? `${route.title} | ${dAppName}` : `${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});

export default mappedRoutes;
