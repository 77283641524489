import React, { useState, useEffect } from 'react';
import Countdown from 'react-countdown';
import { Form, ProgressBar } from 'react-bootstrap';
import SoldOut from 'assets/img/privatesale/soldout.png';
import { Account, Address, AddressValue, ContractFunction, ResultsParser, SmartContract, TokenPayment, U64Value } from '@multiversx/sdk-core/out';
import { adminList, contracts, ElrondApiUrl, isDev, UsdcTokenId } from 'config';
import { useGetAccountInfo, useGetNetworkConfig, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'routes';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';

import { logout } from '@multiversx/sdk-dapp/utils';
import BigNumber from 'bignumber.js/bignumber';
import { BigZero, convertWeiToEsdt, shortenAddress, shortenEsdt } from 'utils';
import { BiLinkExternal } from 'react-icons/bi';

import ONE from 'assets/img/token/one.png';
import axios from 'axios';
import { sendTransactions } from '@multiversx/sdk-dapp/services';

const Vesting = () => {
    const [elapsedTime, setElapsedTime] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false);

    const [lockedAmount ,setLockedAmoount] = useState(BigZero);
    const [releasedAmount, setReleasedAmount] = useState(BigZero);
    const [availableAmount, setAvailableAmount] = useState(BigZero);

    const [flag, setFlag] = useState(false);

    const { account: {address: userAddress, balance} } = useGetAccountInfo();
    const navigate = useNavigate();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const { network: { apiAddress } } = useGetNetworkConfig();

    const networkProvider = new ProxyNetworkProvider(apiAddress, { timeout: 10000 });

    const privateSaleContractAddress = new Address(contracts.PrivateSale.address);
    const privateSaleSmartContract = new SmartContract({ address: privateSaleContractAddress, abi: contracts.PrivateSale.abi });

    useEffect(() => {
        getUserSaleInfo();
    }, [hasPendingTransactions]);

    useEffect(() => {
        if(!elapsedTime) return;

        const timer = setTimeout(() => {
            getUserSaleInfo();

            setFlag(!flag);
        }, 60000);
        
        return () => clearTimeout(timer);
    }, [flag, elapsedTime]);

    useEffect(() => {
        if(!userAddress) {
          navigate(routeNames.unlock);
        }
    
        if(adminList.includes(userAddress)) {
          setIsAdmin(true);
        }
    
        getUserSaleInfo();
    }, [userAddress]);

    const getUserSaleInfo = () => {
        if(!userAddress) {
            return; 
        }

        (async () => {
            try {
                const query = privateSaleSmartContract.createQuery({
                func: new ContractFunction('getUserSaleInfo'),
                args: [
                    new AddressValue(new Address(userAddress))
                ]
                });
            
                const queryResponse = await networkProvider.queryContract(query);
            
                const resultsParser = new ResultsParser();
                const endpointDefinition = privateSaleSmartContract.getEndpoint('getUserSaleInfo');
            
                const { firstValue } = resultsParser.parseQueryResponse(queryResponse, endpointDefinition);
        
                const info = firstValue?.valueOf();

                const startTime = info.sale_start_at.toNumber() * 1000;

                setLockedAmoount(info.bought_amount);
                setReleasedAmount(info.released_amount);
                setAvailableAmount(info.withdrawable_amount);

                if(startTime > Date.now()) {
                    setElapsedTime(0);
                } else {
                    setElapsedTime(Date.now() - startTime);
                }
            } catch(e) {
            }
        })();
    };


    const withdraw = () => {
        if(
            !userAddress ||
            BigZero.gte(availableAmount)
        ) {
            return;
        }

        (async () => {
            try {
                const userAccount = new Account(new Address(userAddress));
  
                const updatedUserAccount = await networkProvider.getAccount(
                    new Address(userAddress)
                );

                userAccount.update(updatedUserAccount);

                const tx = privateSaleSmartContract.methodsExplicit['withdraw']([])
                .withNonce(userAccount.nonce)
                .withGasLimit(30000000)
                .withChainID(isDev ? 'D' : '1');
            
                const transaction = tx.buildTransaction();

                const transactions = [];
                const txData = {
                    value: '0',
                    data: transaction.getData().toString(),
                    receiver: contracts.PrivateSale.address,
                    gasLimit: '30000000'
                };
                transactions.push(txData);

                await sendTransactions({
                    transactions: transactions,
                    transactionsDisplayInfo: {
                        processingMessage: `Withdraw $ONE from Vesting`,
                        errorMessage: 'Transaction Failed',
                        successMessage: 'Transaction Success',
                    },
                    redirectAfterSign: false,
                });
            } catch(e) {
            }
        })();
    };

    const paddingZero = (value: number) => {
        return value.toString().padStart(2, '0');
    };

    return (
        <div className='container'>
            <div className='private-sale' style={{ marginTop: '100px' }}>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <div className='whitelist-title'>
                        Vesting
                    </div> 
                </div>

                <div className='text-center mt-4'>
                    Elapsed Time
                </div>
                <div className='d-flex justify-content-center align-items-center'>
                    <div
                        className='countdown-card'
                    >
                        <span style={{ fontSize: 40, fontWeight: 600 }}>
                            {Math.floor(elapsedTime / (1000 * 3600 * 24))}
                        </span>
                        <span style={{ fontSize: 12, }}>
                            DAYS
                        </span>
                    </div>
                    <div className='mx-2' style={{ fontSize: 24, fontWeight: 600 }}>:</div>
                    <div
                        className='countdown-card'
                    >
                        <span style={{ fontSize: 40, fontWeight: 600 }}>
                            {paddingZero(Math.floor(elapsedTime % (1000 * 3600 * 24) / (1000 * 3600)))}
                        </span>
                        <span style={{ fontSize: 12, }}>
                            HOURS
                        </span>
                    </div>
                    <div className='mx-2' style={{ fontSize: 24, fontWeight: 600 }}>:</div>
                    <div
                        className='countdown-card'
                    >
                        <span style={{ fontSize: 40, fontWeight: 600 }}>
                            {paddingZero(Math.floor(elapsedTime % (1000 * 3600) / (1000 * 60)))}
                        </span>
                        <span style={{ fontSize: 12, }}>
                            MINUTES
                        </span>
                    </div>
                </div>

                <div className='row mt-5' style={{ rowGap: '15px' }}>
                    <div className='col-sm-6 text-center'>
                        Connected Address: {shortenAddress(userAddress)}
                    </div>

                    <div className='col-sm-6 text-center my-2'>
                        <a className='custom-navbar-button auth-button text-center mt-3' href='#'
                            onClick={(e) => {
                                e.preventDefault();

                                logout();
                            }}
                        >
                            <span>Connect Another Wallet</span>
                        </a>
                    </div>
                    
                    <div className='col-sm-6 text-center mt-4'>
                        Locked Amount: {shortenEsdt(convertWeiToEsdt(lockedAmount.minus(releasedAmount), 18))}

                        <img className='ml-1' src={ONE} width={25} alt='ONE' />
                    </div>
                    <div className='col-sm-6 text-center mt-4'>
                        Withdrawable Amount: {shortenEsdt(convertWeiToEsdt(availableAmount, 18))}
                        <img className='ml-1' src={ONE} width={25} alt='ONE' />
                    </div>
                    <div className='col-sm-6 text-center mt-4'>
                        <a className='custom-navbar-button auth-button text-center mt-3' href='#'
                            onClick={(e) => {
                                e.preventDefault();

                                window.open("https://docs.google.com/spreadsheets/d/1MUhl3BhrK7EvdI2Q5zIFsVdnbyfGDWt9g-kBb95S7xM/edit?usp=sharing"); 
                            }}
                        >
                            <span>
                                Token Metrics
                                <BiLinkExternal className='ml-1' />
                            </span>
                        </a>
                    </div>
                    <div className='col-sm-6 text-center mt-4'>
                        <a className='custom-navbar-button auth-button text-center mt-3' href='#'
                            onClick={(e) => {
                                e.preventDefault();

                                withdraw();
                            }}
                        >
                            <span>Withdraw</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Vesting;